<template>
    <zw-sidebar @shown="shown" :title="$t('deposit.title.refund')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col sm="3">
                        <b-form-group :label="$t('deposit.label.full_refund')">
                            <b-input-group>
                                <b-form-checkbox
                                    v-model="form.full_refund"
                                    @input="refund"
                                    :value="true"
                                    :disabled="readOnly"
                                ></b-form-checkbox>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col sm="9">
                        <zw-input-group v-model="form.amount"
                                        name="amount"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                                        :readonly="readOnly || form.full_refund"
                        ></zw-input-group>
                    </b-col>

                    <b-col cols="12">
                        <zw-select-group v-model="form.vat"
                                         :options="getVats() | optionsVL"
                                         name="vat"
                                         :label-prefix="labelPrefix"
                                         validate="required"
                                         :readonly="readOnly"
                        ></zw-select-group>
                    </b-col>

                    <b-col sm="12">
                        <zw-date-group v-model="form.date"
                                       name="date"
                                       :label-prefix="labelPrefix"
                                       validate="required"
                                       :readonly="readOnly"
                        ></zw-date-group>
                    </b-col>

                    <b-col sm="12">
                        <zw-textarea-group v-model="form.reason"
                                           name="reason"
                                           :label-prefix="labelPrefix"
                                           :readonly="readOnly"
                        ></zw-textarea-group>
                    </b-col>
                </b-row>

                <b-row v-if="!readOnly">
                    <b-col sm="12" class="text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="6" class="text-sm-right">
                                <b-button block @click="onSubmit()" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            labelPrefix: 'deposit.label.',
            payload: {},
            callback: null,
            readOnly: false,
            defaultForm: {
                position_id: null,
                full_refund: true,
                date: null,
            },
            form: {},
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getVats']),
        ...mapGetters('Deposits', ['getDeposit']),
        shown() {
            this.defaultForm.position_id = this.payload.id
            this.readOnly = this.payload.status == 'refunded'

            this.$store.dispatch('Deposits/fetchDeposit', {
                id: this.payload.id,
            })
                .then((data) => {
                    this.form = {
                        ...JSON.parse(JSON.stringify(this.defaultForm)),
                        ...JSON.parse(JSON.stringify(this.getDeposit()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Deposits/saveRefund', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        refund(val) {
            if (val) {
                this.form.amount = this.form.full_amount
            } else {
                this.form.amount = this.getDeposit().amount
            }
        }
    }
}
</script>